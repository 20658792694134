import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import * as moment from 'moment';
import { ThemeService } from 'ng2-charts';
import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { finalize } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import {
  base64ToFile,
  convertFile,
} from 'src/app/core/services/utils.service';
import {
  JOB_LIST,
  NUMBEROFRESIDENT_LIST,
  PREFIX,
  TYPE_COMPANY,
} from 'src/app/modules/customer-page/shared/customer-page';
import { UsersService } from 'src/app/modules/users/shared/users.service';
import {
  DropdownResponse,
  DropdownService,
} from 'src/app/shared/service/dropdown.service';
import { ImageProcessingService } from 'src/app/shared/service/image-processing.service';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import { idNumberValidator } from 'src/app/shared/utils/validator';
import { CustomerUserService } from '../../services/customer-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss'],
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  readonly emailPattern =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  @Input() detail;
  @Input() person;
  @Output() back = new EventEmitter();
  formGroup: FormGroup;
  previousProvinceEvent: any = null;
  previousProvinceContactEvent: any = null;
  previousDistricEvent: any = null;
  previousDistricContactEvent: any = null;
  previousPostalCodeEvent: any = null;
  previousPostalCodeContactEvent: any = null;
  refreshDropdown = false;
  loadSave = false;
  translated;
  typeUserItem = [
    {
      label: 'บุคคลธรรมดา',
      label_en: 'Individual',
      value: 'individual',
    },
    {
      label: 'นิติบุคคล',
      label_en: 'Company',
      value: 'company',
    },
  ];
  prefixItem = PREFIX;
  prefixCompany = TYPE_COMPANY;
  prefixTypeCard = [
    {
      label: 'บัตรประชาชน',
      label_en: 'ID Card',
      value: 'citizen_id',
    },
    {
      label: 'หนังสือเดินทาง',
      label_en: 'Passport',
      value: 'passport_id',
    },
  ];
  prefixCareer = JOB_LIST;
  NumberOfOccupantList = NUMBEROFRESIDENT_LIST;
  statusAddress = false;
  proviceItem: { label: any; value: any; context?: any }[];
  districtItem: { label: any; value: any; context?: any }[];
  subdistrictitem: { label: any; value: any; context?: any }[];
  locationItem: { label: any; value: any; context?: any }[];
  propertyItem: { label: any; value: any; context?: any }[];
  modal: NgbModalRef;
  // photo
  photo;
  photoPreview: string | ArrayBuffer = '';
  fileInputPhoto: any;
  imageChangedEventPhoto: string;
  croppedImagePhoto: string;
  blobOutputPhoto: any;
  imageURLPhoto = '';
  cropper: CropperPosition = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  };
  @ViewChild('imageCropper')
  imageCropperComponent: ImageCropperComponent;
  errorMsg: string;
  // icon
  chevronLeft = faChevronLeft;
  submitted = false;
  individualId: number;
  companyId: number;
  fileInput: any;
  minDate = {
    year: new Date().getFullYear() - 100,
    month: 1,
    day: 1,
  };
  currentDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  itemBuilding = [];
  subscription: Subscription[] = [];
  postalCodeDropdown = [];
  postalCodeContactDropdown = [];

  constructor(
    private dropdownService: DropdownService,
    private swal: SweetAlertService,
    private activateRoute: ActivatedRoute,
    private themeService: ThemeService,
    private translate: TranslateService,
    private builder: FormBuilder,
    private alert: AlertService,
    private modalService: NgbModal,
    private spinner: SpinnerService,
    private imageProcessingService: ImageProcessingService,
    private customerService: CustomerUserService,
    private service: UsersService,
  ) {
    this.translated = translate.currentLang;
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translated = translate.currentLang;
        this.translateDropdown();
      },
    );
  }

  ngOnInit(): void {
    this.formGroup = this.builder.group({
      type_user: [null],
      // individual
      card_type: [null, Validators.required],
      card_no: [
        null,
        [
          Validators.required,
          Validators.minLength(13),
          idNumberValidator,
        ],
      ],
      name_prefix: [null, Validators.required],
      other_prefix: [{ value: null, disabled: true }],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      birth_date: [null, Validators.required],
      job: [null, Validators.required],
      job_other: [null],
      line_id: [null],
      // company
      registration_id: [
        null,
        [Validators.minLength(10), Validators.required],
      ],
      company_type: [null, Validators.required],
      other_company_type: [{ value: null, disabled: true }],
      business_name: [null, Validators.required],
      committees: this.builder.array([
        this.createCommitteeFormGroup(0),
        this.createCommitteeFormGroup(1),
        this.createCommitteeFormGroup(2),
      ]),
      contact_prefix: [null, Validators.required],
      contact_prefix_other: [null],
      contact_first_name: [null, Validators.required],
      contact_last_name: [null, Validators.required],
      contact_email: [null, [Validators.required, Validators.email]],
      contact_phone_number: [
        null,
        [Validators.required, Validators.minLength(10)],
      ],

      house_no: [null, Validators.required],
      village_no: [null],
      road: [null],
      province: [null, Validators.required],
      district: [{ value: null }, Validators.required],
      sub_district: [{ value: null }, Validators.required],
      postal_code: [{ value: null }, Validators.required],
      contact_house_no: [
        { value: null, disabled: false },
        Validators.required,
      ],
      contact_village_no: [{ value: null, disabled: false }],
      contact_road: [{ value: null, disabled: false }],
      contact_province: [
        { value: null, disabled: false },
        Validators.required,
      ],
      contact_district: [
        { value: null, disabled: true },
        Validators.required,
      ],
      contact_sub_district: [
        { value: null, disabled: true },
        Validators.required,
      ],
      contact_postal_code: [
        { value: null, disabled: true },
        Validators.required,
      ],
      email: [null, [Validators.pattern(this.emailPattern)]],
      secondary_email: [
        null,
        [Validators.pattern(this.emailPattern)],
      ],
      phone_number: [null, [Validators.minLength(10)]],
      secondary_phone_number: [null, [Validators.minLength(10)]],
      property: [null, Validators.required],
      location: [null, Validators.required],
      building: [null, Validators.required],
      room_reserve: [null, Validators.required],
      duration_contract: [null, Validators.required],
      additional_details: [null],
      number_of_occupants: [null],
    });
    this.subscription.push(
      this.activateRoute.queryParams.subscribe((res) => {
        if (res.id) {
          if (res.type === 'individual') {
            this.setPatchValueIndividual();
            this.formGroup.get('type_user').setValue('individual');
            this.individualId = res.id;
          } else if (res.type === 'company') {
            this.companyId = res.id;
            this.setPatchValueCompany();
            this.formGroup.get('type_user').setValue('company');
          }
        }

        this.getItemProvince();
        this.getLocationItem();
        // this.getBuildingItem();
      }),
    );
    if (this.formGroup.value.type_user === 'company') {
      this.setValidatorCommittee(0);
      const committees = this.formGroup.get(
        'committees',
      ) as FormArray;
      const committee = committees.at(0) as FormGroup;
      const phoneNumberControl = committee.get('phone_number');
      phoneNumberControl.setValidators([Validators.minLength(10)]);
      phoneNumberControl.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  createCommitteeFormGroup(index: number) {
    const group = this.builder.group({
      id: [],
      prefix: [],
      prefix_other: [],
      first_name: [],
      last_name: [],
      email: [],
      phone_number: [],
    });

    if (index === 0) {
      const prefixControl = group.get('prefix');
      prefixControl.setValidators([Validators.required]);
      prefixControl.updateValueAndValidity();

      const firstnameControl = group.get('first_name');
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();

      const lastnameControl = group.get('last_name');
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();

      const emailControl = group.get('email');
      emailControl.setValidators([
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]);
      emailControl.updateValueAndValidity();

      const phoneNumberControl = group.get('phone_number');
      phoneNumberControl.setValidators([
        Validators.required,
        Validators.minLength(10),
      ]);
      phoneNumberControl.updateValueAndValidity();
    }

    return group;
  }

  clearCommitteesValidators() {
    (this.formGroup.controls.committees as any).controls.forEach(
      (committee) => {
        Object.keys(committee.controls).forEach((key) => {
          committee.get(key).clearValidators();
        });
      },
    );
  }
  setValidatorCommittee(index: number): void {
    if (this.formGroup.value.type_user === 'company') {
      const committeesArray = this.formGroup.get(
        'committees',
      ) as FormArray;
      const committeeGroup = committeesArray.at(index) as FormGroup;
      committeeGroup.get('prefix').setValidators(Validators.required);
      committeeGroup
        .get('first_name')
        .setValidators(Validators.required);
      committeeGroup
        .get('last_name')
        .setValidators(Validators.required);
      committeeGroup
        .get('email')
        .setValidators([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]);
      committeeGroup
        .get('phone_number')
        .setValidators([
          Validators.required,
          Validators.minLength(10),
        ]);
      if (index > 0 && this.isCommitteesEmpty[index - 1]) {
        committeeGroup.get('prefix').clearValidators();
        committeeGroup.get('first_name').clearValidators();
        committeeGroup.get('last_name').clearValidators();
        committeeGroup.get('email').clearValidators();
        committeeGroup
          .get('phone_number')
          .setValidators([
            Validators.required,
            Validators.minLength(10),
          ]);
        committeeGroup.get('id').setValue(null);

        const canClearValidate: boolean = Object.keys(
          committeeGroup?.value,
        )
          .filter(
            (keys: string) => !['id', 'prefix_other'].includes(keys),
          )
          .every((key: string) => !committeeGroup?.value[key]);

        if (canClearValidate) {
          committeeGroup.get('phone_number').clearValidators();
        }
      }
      committeeGroup.get('prefix').updateValueAndValidity();
      committeeGroup.get('first_name').updateValueAndValidity();
      committeeGroup.get('last_name').updateValueAndValidity();
      committeeGroup.get('email').updateValueAndValidity();
      committeeGroup.get('phone_number').updateValueAndValidity();
    } else if (this.formGroup.value.type_user === 'individual') {
      const committees = this.formGroup.get(
        'committees',
      ) as FormArray;
      const committee = committees.at(index) as FormGroup;

      if (index === 1 || index === 2) {
        const prefixControl = committee.get('prefix');
        prefixControl.setValidators([Validators.required]);
        prefixControl.updateValueAndValidity();

        const firstnameControl = committee.get('first_name');
        firstnameControl.setValidators([Validators.required]);
        firstnameControl.updateValueAndValidity();

        const lastnameControl = committee.get('last_name');
        lastnameControl.setValidators([Validators.required]);
        lastnameControl.updateValueAndValidity();

        const emailControl = committee.get('email');
        emailControl.setValidators([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]);
        emailControl.updateValueAndValidity();

        const phoneNumberControl = committee.get('phone_number');
        phoneNumberControl.setValidators([
          Validators.required,
          Validators.minLength(10),
        ]);
        phoneNumberControl.updateValueAndValidity();
      }
    }
  }
  onChange(name_prefix, index) {
    const committeeArray = this.formGroup.get(
      'committees',
    ) as FormArray;
    const committeeGroup = committeeArray.at(index) as FormGroup;

    if (name_prefix && name_prefix.value === 'other') {
      committeeGroup
        .get('prefix_other')
        .setValidators(Validators.required);
    } else {
      committeeGroup.get('prefix_other').clearValidators();
      committeeGroup.get('prefix_other').setValue('');
    }
    committeeGroup.get('prefix_other').updateValueAndValidity();
    this.setValidatorCommittee(index);
  }
  get isCommitteesEmpty(): boolean[] {
    const committees = this.formGroup.get('committees').value;
    const selectedCommittees = committees.slice(1, 3);
    return selectedCommittees.map((committee) => {
      const committeeValues = Object.values(committee);
      const valuesToCheck = committeeValues.slice(1);
      return valuesToCheck.every((val) => !val);
    });
  }

  setPatchValueIndividual() {
    // remove validate
    this.formGroup.get('registration_id').clearValidators();
    this.formGroup.get('company_type').clearValidators();
    this.formGroup.get('other_company_type').clearValidators();
    this.formGroup.get('business_name').clearValidators();
    this.formGroup.get('contact_prefix').clearValidators();
    this.formGroup.get('contact_prefix_other').clearValidators();
    this.formGroup.get('contact_first_name').clearValidators();
    this.formGroup.get('contact_last_name').clearValidators();
    this.formGroup.get('contact_email').clearValidators();
    this.formGroup.get('contact_phone_number').clearValidators();
    this.formGroup
      .get('phone_number')
      .setValidators([Validators.minLength(10)]);
    this.formGroup.get('phone_number').updateValueAndValidity();
    this.clearCommitteesValidators();
    this.formGroup.patchValue({
      card_type: this.detail.card_type,
      card_no: this.detail.card_no,
      name_prefix: this.detail.name_prefix,
      other_prefix: this.detail.other_prefix,
      first_name: this.detail.first_name,
      last_name: this.detail.last_name,
      birth_date: this.detail.birth_date,
      job: this.detail.job,
      job_other: this.detail.job_other,
      line_id: this.detail.line_id,
      house_no: this.detail.house_no,
      village_no: this.detail.village_no,
      road: this.detail.road,
      province: this.detail.province,
      district: this.detail.district,
      sub_district: this.detail.sub_district,
      postal_code: this.detail.postal_code,
      contact_house_no: this.detail.contact_house_no,
      contact_village_no: this.detail.contact_village_no,
      contact_road: this.detail.contact_road,
      contact_province: this.detail.contact_province,
      contact_district: this.detail.contact_district,
      contact_sub_district: this.detail.contact_sub_district,
      contact_postal_code: this.detail.contact_postal_code,
      email: this.detail.email,
      secondary_email: this.detail.secondary_email,
      phone_number: this.detail.phone_number,
      secondary_phone_number: this.detail.secondary_phone_number,
      property: this.detail.property,
      location: this.detail.location,
      building: this.detail.building,
      room_reserve: this.detail.room_reserve || '',
      duration_contract: this.detail.duration_contract || '',
      additional_details: this.detail.additional_details || '',
      number_of_occupants: this.detail.number_of_occupants || null,
    });
    if (this.detail.card_type === 'passport_id') {
      this.formGroup
        .get('card_no')
        .setValidators([Validators.required]);
      this.formGroup.get('card_no').updateValueAndValidity();
      // reset form
      this.formGroup.get('house_no').reset();
      this.formGroup.get('village_no').reset();
      this.formGroup.get('road').reset();
      this.formGroup.get('province').reset();
      this.formGroup.get('district').reset();
      this.formGroup.get('sub_district').reset();
      this.formGroup.get('postal_code').reset();
      // disabled form
      this.formGroup.get('house_no').disable();
      this.formGroup.get('village_no').disable();
      this.formGroup.get('road').disable();
      this.formGroup.get('province').disable();
      this.formGroup.get('district').disable();
      this.formGroup.get('sub_district').disable();
      this.formGroup.get('postal_code').disable();
    }
    this.getItemDistrict(this.detail.province);
    this.getItemDistrictContact(this.detail.contact_province);
    this.getItemSubDistrict(this.detail.district);
    this.getItemSubDistrictContact(this.detail.contact_district);
    this.getPostralCode(this.detail.sub_district);
    this.getPostralCodeContact(this.detail.contact_sub_district);

    this.photo = this.person?.photo || '';
    this.photoPreview = this.person?.photo || '';
    if (this.detail.location) {
      this.getItemProperty(this.detail.location);
    }
    if (this.detail.property) {
      this.getBuildingItem(this.detail.property);
    }
    if (this.detail.other_prefix) {
      this.formGroup.get('other_prefix').enable();
    }
    this.formGroup.updateValueAndValidity();
    setTimeout(() => {
      this.statusAddress = this.compareAddress();
      if (this.statusAddress) {
        this.onSelectCopyAddress(true);
      }
    }, 100);
  }

  compareAddress() {
    const address = {
      house_no: this.detail.house_no,
      village_no: this.detail.village_no,
      road: this.detail.road,
      province: this.detail.province,
      district: this.detail.district,
      sub_district: this.detail.sub_district,
      postal_code: this.detail.postal_code,
    };
    const contactAddress = {
      house_no: this.detail.contact_house_no,
      village_no: this.detail.contact_village_no,
      road: this.detail.contact_road,
      province: this.detail.contact_province,
      district: this.detail.contact_district,
      sub_district: this.detail.contact_sub_district,
      postal_code: this.detail.contact_postal_code,
    };
    return Boolean(
      JSON.stringify(address) === JSON.stringify(contactAddress) &&
        (this.detail.card_type === 'citizen_id' ||
          this.formGroup.value.type_user === 'company'),
    );
  }

  setPatchValueCompany() {
    this.getItemDistrict(this.detail.province);
    this.getItemDistrictContact(this.detail.contact_province);
    this.getItemSubDistrict(this.detail.district);
    this.getItemSubDistrictContact(this.detail.contact_district);
    this.getPostralCode(this.detail.sub_district);
    this.getPostralCodeContact(this.detail.contact_sub_district);
    const committees = this.formGroup.get('committees') as FormArray;
    for (let i = 0; i < committees.length; i++) {
      const committee = committees.at(i) as FormGroup;
      const detailCommittee = this.detail.committees[i];
      committee.patchValue({
        id: detailCommittee?.id || null,
        prefix: detailCommittee?.prefix || null,
        prefix_other: detailCommittee?.prefix_other || null,
        first_name: detailCommittee?.first_name || null,
        last_name: detailCommittee?.last_name || null,
        email: detailCommittee?.email || null,
        phone_number: detailCommittee?.phone_number || null,
      });
    }

    // remove validate
    this.formGroup.get('card_type').clearValidators();
    this.formGroup.get('card_no').clearValidators();
    this.formGroup.get('name_prefix').clearValidators();
    this.formGroup.get('other_prefix').clearValidators();
    this.formGroup.get('first_name').clearValidators();
    this.formGroup.get('last_name').clearValidators();
    this.formGroup.get('birth_date').clearValidators();
    this.formGroup.get('job').clearValidators();
    this.formGroup.get('job_other').clearValidators();
    this.formGroup.get('line_id').clearValidators();
    this.formGroup
      .get('contact_phone_number')
      .setValidators([Validators.required, Validators.minLength(10)]);
    this.formGroup
      .get('contact_phone_number')
      .updateValueAndValidity();
    this.formGroup.patchValue({
      // type_user: 'company',
      registration_id: this.detail.registration_id,
      company_type: this.detail.company_type,
      other_company_type: this.detail.other_company_type,
      business_name: this.detail.business_name,
      committees: this.detail.committees,
      house_no: this.detail.house_no,
      village_no: this.detail.village_no,
      road: this.detail.road,
      province: this.detail.province,
      district: this.detail.district,
      sub_district: this.detail.sub_district,
      postal_code: this.detail.postal_code,
      contact_house_no: this.detail.contact_house_no,
      contact_village_no: this.detail.contact_village_no,
      contact_road: this.detail.contact_road,
      contact_province: this.detail.contact_province,
      contact_district: this.detail.contact_district,
      contact_sub_district: this.detail.contact_sub_district,
      contact_postal_code: this.detail.contact_postal_code,
      email: this.detail.email,
      secondary_email: this.detail.secondary_email,
      contact_prefix: this.detail.contact_prefix,
      contact_prefix_other: this.detail.contact_prefix_other,
      contact_first_name: this.detail.contact_first_name,
      contact_last_name: this.detail.contact_last_name,
      contact_email: this.detail.contact_email,
      contact_phone_number: this.detail.contact_phone_number,
      phone_number: this.detail.phone_number,
      secondary_phone_number: this.detail.secondary_phone_number,
      property: this.detail.property,
      location: this.detail.location,
      building: this.detail.building,
      room_reserve: this.detail.room_reserve || '',
      duration_contract: this.detail.duration_contract || '',
      additional_details: this.detail.additional_details || '',
      number_of_occupants: this.detail.number_of_occupants || null,
    });
    this.formGroup.get('postal_code').enable();
    this.formGroup.get('contact_postal_code').enable();
    this.photo = this.person?.photo || '';
    this.photoPreview = this.person?.photo || '';
    if (this.detail.location) {
      this.getItemProperty(this.detail.location);
    }
    if (this.detail.property) {
      this.getBuildingItem(this.detail.property);
    }
    if (this.detail.other_company_type) {
      this.formGroup.get('other_company_type').enable();
    }
    this.formGroup.updateValueAndValidity();
    setTimeout(() => {
      this.statusAddress = this.compareAddress();
      if (this.statusAddress) {
        this.onSelectCopyAddress(true);
      }
    }, 100);
  }

  translateDropdown(): void {
    this.refreshDropdown = true;
    setTimeout(() => {
      this.refreshDropdown = false;
    }, 1);
  }

  blindingEditDropdown(isFirstChanged = false): void {
    if (isFirstChanged && this.detail.property) {
      this.getItemProperty(this.detail.location);
    }
    if (isFirstChanged && this.detail.building) {
      this.getBuildingItem(this.detail.property);
    }
  }

  changeTypeTitleName(event): void {
    if (event === 'other') {
      this.formGroup.get('other_prefix').enable();
      this.formGroup
        .get('other_prefix')
        .setValidators([Validators.required]);
      this.formGroup
        .get('other_prefix')
        .setErrors({ required: true });
    } else {
      this.formGroup.get('other_prefix').disable();
      this.formGroup.get('other_prefix').setValue(null);
      this.formGroup.get('other_prefix').setErrors(null);
      this.formGroup.get('other_prefix').clearValidators();
    }
    this.formGroup.updateValueAndValidity();
  }

  changeTypeTitleCompany(event): void {
    if (event === 'other') {
      this.formGroup.get('other_company_type').enable();
      this.formGroup
        .get('other_company_type')
        .setValidators([Validators.required]);
      this.formGroup
        .get('other_company_type')
        .setErrors({ required: true });
    } else {
      this.formGroup.get('other_company_type').disable();
      this.formGroup.get('other_company_type').setValue(null);
      this.formGroup.get('other_company_type').setErrors(null);
      this.formGroup.get('other_company_type').clearValidators();
    }
    this.formGroup.updateValueAndValidity();
  }

  changeCardTypeForDisabledForm(event): void {
    if (event === 'passport_id') {
      // reset form
      this.formGroup.get('house_no').reset();
      this.formGroup.get('village_no').reset();
      this.formGroup.get('road').reset();
      this.formGroup.get('province').reset();
      this.formGroup.get('district').reset();
      this.formGroup.get('sub_district').reset();
      this.formGroup.get('postal_code').reset();
      // disabled form
      this.formGroup.get('house_no').disable();
      this.formGroup.get('village_no').disable();
      this.formGroup.get('road').disable();
      this.formGroup.get('province').disable();
      this.formGroup.get('district').disable();
      this.formGroup.get('sub_district').disable();
      this.formGroup.get('postal_code').disable();
      // reset validate for passport
      this.formGroup.get('card_no').clearValidators();
      this.formGroup
        .get('card_no')
        .setValidators([Validators.required]);
      this.formGroup.updateValueAndValidity();
    } else {
      // enable form
      this.formGroup.get('house_no').enable();
      this.formGroup.get('village_no').enable();
      this.formGroup.get('road').enable();
      this.formGroup.get('province').enable();
      this.formGroup.get('district').enable();
      this.formGroup.get('sub_district').enable();
      this.formGroup.get('postal_code').enable();
      // reset validate for card id
      this.formGroup.get('card_no').clearValidators();
      this.formGroup
        .get('card_no')
        .setValidators([
          Validators.required,
          Validators.minLength(13),
          idNumberValidator,
        ]);
      this.formGroup.updateValueAndValidity();
    }
  }

  getItemProvince(): void {
    const params = {
      type: 'province',
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.proviceItem = dropdown.province;
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
  }

  clearValueAddress(): void {
    this.formGroup.get('district').disable();
    this.formGroup.get('sub_district').disable();
    this.formGroup.get('postal_code').disable();
    this.formGroup.get('province').reset();
    this.formGroup.get('district').reset();
    this.formGroup.get('sub_district').reset();
    this.formGroup.get('postal_code').reset();
  }

  clearValueCopyAddress(): void {
    this.formGroup.get('contact_district').disable();
    this.formGroup.get('contact_sub_district').disable();
    this.formGroup.get('contact_postal_code').disable();
    this.formGroup.get('contact_province').reset();
    this.formGroup.get('contact_district').reset();
    this.formGroup.get('contact_sub_district').reset();
    this.formGroup.get('contact_postal_code').reset();
  }

  getItemDistrict(event): void {
    this.districtItem = [];
    const isEventChangedFromNull =
      this.previousProvinceEvent !== null;
    const params = {
      type: 'district',
      province: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.districtItem = dropdown.district;
          if (event) {
            this.formGroup.get('district').enable();
            if (isEventChangedFromNull) {
              this.formGroup.get('district').reset();
              this.formGroup.get('sub_district').reset();
              this.formGroup.get('postal_code').reset();
            }
            if (!this.subDistrict) {
              this.formGroup.get('sub_district').disable();
            }
            if (!this.postalCode) {
              this.formGroup.get('postal_code').disable();
            }
          }
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
    this.previousProvinceEvent = event;
  }

  get postalCode() {
    return this.formGroup.get('postal_code').value;
  }
  get contactPostalCode() {
    return this.formGroup.get('contact_postal_code').value;
  }
  get subDistrict() {
    return this.formGroup.get('district').value;
  }
  get contactSubDistrict() {
    return this.formGroup.get('contact_sub_district').value;
  }

  onChangePostalCode(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_postal_code.setValue(
        this.formGroup.get('postal_code').value,
      );
      this.disableContactAddress();
    }
  }

  getItemDistrictContact(event): void {
    this.districtItem = [];
    const isEventChangedFromNull =
      this.previousProvinceContactEvent !== null;
    const params = {
      type: 'district',
      province: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.districtItem = dropdown.district;
          if (event && !this.statusAddress) {
            this.formGroup.get('contact_district').enable();
            if (isEventChangedFromNull) {
              this.formGroup.get('contact_district').reset();
              this.formGroup.get('contact_sub_district').reset();
              this.formGroup.get('contact_postal_code').reset();
            }
            if (!this.contactSubDistrict) {
              this.formGroup.get('contact_sub_district').disable();
            }
            if (!this.contactPostalCode) {
              this.formGroup.get('contact_postal_code').disable();
            }
          }
          if (this.statusAddress) {
            this.formGroup.get('contact_district').disable();
            if (!this.contactSubDistrict) {
              this.formGroup.get('contact_sub_district').disable();
            }
            if (!this.contactPostalCode) {
              this.formGroup.get('contact_postal_code').disable();
            }
          }
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
    this.previousProvinceContactEvent = event;
  }

  getItemSubDistrict(event): void {
    this.subdistrictitem = [];
    const isEventChangedFromNull = this.previousDistricEvent !== null;
    const params = {
      type: 'subdistrict',
      district: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.subdistrictitem = dropdown.subdistrict;
          if (event) {
            this.formGroup.get('sub_district').enable();
            if (isEventChangedFromNull) {
              this.formGroup.get('sub_district').reset();
              this.formGroup.get('postal_code').reset();
            }
            if (!this.postalCode) {
              this.formGroup.get('postal_code').disable();
            }
          }
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
    this.previousDistricEvent = event;
  }

  getItemSubDistrictContact(event): void {
    this.subdistrictitem = [];
    const isEventChangedFromNull =
      this.previousDistricContactEvent !== null;
    const params = {
      type: 'subdistrict',
      district: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.subdistrictitem = dropdown.subdistrict;
          if (event && !this.statusAddress) {
            this.formGroup.get('contact_sub_district').enable();
            if (isEventChangedFromNull) {
              this.formGroup.get('contact_sub_district').reset();
              this.formGroup.get('contact_postal_code').reset();
            }
            if (!this.contactPostalCode) {
              this.formGroup.get('contact_postal_code').disable();
            }
          }
          if (this.statusAddress) {
            if (!this.contactSubDistrict) {
              this.formGroup.get('contact_sub_district').disable();
            }
            if (!this.contactPostalCode) {
              this.formGroup.get('contact_postal_code').disable();
            }
          }
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
    this.previousDistricContactEvent = event;
  }

  getPostralCode(event): void {
    this.postalCodeDropdown = [];
    const isEventChangedFromNull =
      this.previousPostalCodeEvent !== null;
    const params = {
      type: 'postal_code',
      sub_district: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.postalCodeDropdown = dropdown.postal_code;
          if (event) {
            if (this.statusAddress) {
              this.formGroup.controls.contact_postal_code.setValue(
                this.formGroup.get('postal_code').value,
              );
            }
            if (isEventChangedFromNull) {
              this.formGroup.get('postal_code').reset();
            }
            if (this.detail.card_type !== 'passport_id') {
              this.formGroup.get('sub_district').enable();
              this.formGroup.get('postal_code').enable();
            }
          } else {
            this.formGroup.get('postal_code').disable();
            this.formGroup.get('postal_code').reset();
          }
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
    this.previousPostalCodeEvent = event;
  }

  getPostralCodeContact(event): void {
    this.postalCodeContactDropdown = [];
    const isEventChangedFromNull =
      this.previousPostalCodeContactEvent !== null;
    const params = {
      type: 'postal_code',
      sub_district: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.postalCodeContactDropdown = dropdown.postal_code;
          if (event && !this.statusAddress) {
            this.formGroup.get('contact_postal_code').enable();
            if (isEventChangedFromNull) {
              this.formGroup.get('contact_postal_code').reset();
            }
            if (this.subDistrict) {
              this.formGroup.get('contact_sub_district').enable();
            }
          } else {
            this.formGroup.get('contact_postal_code').disable();
          }
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
    this.previousPostalCodeContactEvent = event;
  }

  onSelectCopyAddress(event: any): void {
    if (this.statusAddress) {
      this.formGroup.patchValue({
        contact_house_no: this.formGroup.get('house_no').value,
        contact_village_no: this.formGroup.get('village_no').value,
        contact_road: this.formGroup.get('road').value,
        contact_province: this.formGroup.get('province').value,
        contact_district: this.formGroup.get('district').value,
        contact_sub_district:
          this.formGroup.get('sub_district').value,
        contact_postal_code: this.formGroup.get('postal_code').value,
      });
      this.postalCodeContactDropdown = this.postalCodeDropdown;
      this.disableContactAddress();
    } else {
      this.formGroup.patchValue({
        contact_house_no: null,
        contact_village_no: null,
        contact_road: null,
        contact_province: null,
        contact_district: null,
        contact_sub_district: null,
        contact_postal_code: null,
      });
      this.formGroup.get('contact_house_no').enable();
      this.formGroup.get('contact_village_no').enable();
      this.formGroup.get('contact_road').enable();
      this.formGroup.get('contact_province').enable();
      this.formGroup.get('contact_district').disable();
      this.formGroup.get('contact_sub_district').disable();
      this.formGroup.get('contact_postal_code').disable();
    }
  }

  onClickBack(): void {
    this.back.emit(false);
    this.formGroup.reset();
  }

  openConfirmModal(content) {
    this.modal = this.modalService.open(content, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
  }

  openPhoto(content): void {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
      })
      .result.then((result) => {
        this.imageChangedEventPhoto = '';
        this.croppedImagePhoto = '';
        this.imageURLPhoto = '';
        this.fileInputPhoto = '';
      });
    this.photo = '';
  }

  uploadSignature(id): void {
    const fd = new FormData();
    if (this.photo && this.photo.startsWith('data:')) {
      fd.append(
        'photo',
        convertFile(
          base64ToFile(this.photo, 'photo.png'),
          this.fileInput?.name ? this.fileInput?.name : 'photo.png',
        ),
      );
    }
    this.spinner.show();
    this.subscription.push(
      this.service
        .updateUserBlob(fd, id)
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe(
          () => {
            (async () => {
              this.alert.success('Update user success');
              this.back.emit(false);
              this.modalService.dismissAll();
            })();
          },
          (err) => {
            this.alert.error(this.formatError(err.error));
            this.modalService.dismissAll();
          },
        ),
    );
  }

  getFormValidationErrors(): void {
    Object.keys(this.formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          this.alert.error(
            'Key control: ' +
              key +
              ', keyError: ' +
              keyError +
              ', err value: ',
            controlErrors[keyError],
          );
        });
      }
    });
  }

  prepareData() {
    this.loadSave = true;
    this.submitted = true;
    this.getFormValidationErrors();
    /**
     * Check status invalid of keys in from group
     * for (const controlName in this.formGroup.controls) {
     *       console.log(
     *         controlName,
     *         this.formGroup.controls[controlName].status,
     *       );
     *     }
     */

    if (this.formGroup.invalid || this.formGroup?.errors) {
      return this.alert.error();
    }
    if (this.formGroup.value.type_user === 'individual') {
      return {
        card_type: this.formGroup.getRawValue().card_type || '',
        card_no:
          this.formGroup.getRawValue().card_type === 'citizen_id'
            ? this.formGroup.getRawValue().card_no.replace(/\D/g, '')
            : this.formGroup.getRawValue().card_no,
        name_prefix: this.formGroup.getRawValue().name_prefix || '',
        other_prefix: this.formGroup.getRawValue().other_prefix || '',
        first_name: this.formGroup.getRawValue().first_name || '',
        last_name: this.formGroup.getRawValue().last_name || '',
        birth_date: this.formGroup.getRawValue().birth_date || '',
        job: this.formGroup.getRawValue().job || '',
        job_other: this.formGroup.getRawValue().job_other || '',
        line_id: this.formGroup.getRawValue().line_id || '',
        house_no: this.formGroup.getRawValue().house_no || '',
        village_no: this.formGroup.getRawValue().village_no || '',
        road: this.formGroup.getRawValue().road || '',
        province: this.formGroup.getRawValue().province || '',
        district: this.formGroup.getRawValue().district || '',
        sub_district: this.formGroup.getRawValue().sub_district || '',
        postal_code: this.formGroup.getRawValue().postal_code || '',
        contact_house_no:
          this.formGroup.getRawValue().contact_house_no || '',
        contact_village_no:
          this.formGroup.getRawValue().contact_village_no || '',
        contact_road: this.formGroup.getRawValue().contact_road || '',
        contact_province:
          this.formGroup.getRawValue().contact_province || '',
        contact_district:
          this.formGroup.getRawValue().contact_district || '',
        contact_sub_district:
          this.formGroup.getRawValue().contact_sub_district || '',
        contact_postal_code:
          this.formGroup.getRawValue().contact_postal_code || '',
        email: this.formGroup.getRawValue().email || '',
        secondary_email:
          this.formGroup.getRawValue().secondary_email || '',
        phone_number: this.formGroup.getRawValue().phone_number
          ? this.formGroup
              .getRawValue()
              .phone_number.replace(/\D/g, '')
          : '',
        secondary_phone_number: this.formGroup.getRawValue()
          .secondary_phone_number
          ? this.formGroup
              .getRawValue()
              .secondary_phone_number.replace(/\D/g, '')
          : '',
        property: this.formGroup.getRawValue().property || '',
        location: this.formGroup.getRawValue().location || '',
        building: this.formGroup.getRawValue().building || '',
        room_reserve: this.formGroup.getRawValue().room_reserve,
        duration_contract:
          this.formGroup.getRawValue().duration_contract,
        additional_details:
          this.formGroup.getRawValue().additional_details || '',
        number_of_occupants:
          this.formGroup.getRawValue().number_of_occupants || '',
      };
    } else {
      return {
        registration_id: this.formGroup.getRawValue().registration_id
          ? this.formGroup
              .getRawValue()
              .registration_id.replace(/\D/g, '')
          : '',
        company_type: this.formGroup.getRawValue().company_type || '',
        other_company_type:
          this.formGroup.getRawValue().other_company_type || '',
        business_name:
          this.formGroup.getRawValue().business_name || '',
        committees: this.formGroup
          .getRawValue()
          .committees.filter((committee) => {
            return !Object.values(committee).every((val) => {
              return val === null || val === '';
            });
          })
          .map((committee) => {
            return {
              id: committee.id || null,
              prefix: committee.prefix || '',
              prefix_other: committee.prefix_other || '',
              first_name: committee.first_name || '',
              last_name: committee.last_name || '',
              email: committee.email || '',
              phone_number: committee.phone_number
                ? committee.phone_number.replace(/\D/g, '')
                : '',
            };
          }),
        contact_prefix:
          this.formGroup.getRawValue().contact_prefix || '',
        contact_prefix_other:
          this.formGroup.getRawValue().contact_prefix_other || '',
        contact_first_name:
          this.formGroup.getRawValue().contact_first_name || '',
        contact_last_name:
          this.formGroup.getRawValue().contact_last_name || '',
        contact_email:
          this.formGroup.getRawValue().contact_email || '',
        contact_phone_number: this.formGroup.getRawValue()
          .contact_phone_number
          ? this.formGroup
              .getRawValue()
              .contact_phone_number.replace(/\D/g, '')
          : '',
        house_no: this.formGroup.getRawValue().house_no || '',
        village_no: this.formGroup.getRawValue().village_no || '',
        road: this.formGroup.getRawValue().road || '',
        province: this.formGroup.getRawValue().province || '',
        district: this.formGroup.getRawValue().district || '',
        sub_district: this.formGroup.getRawValue().sub_district || '',
        postal_code: this.formGroup.getRawValue().postal_code || '',
        contact_house_no:
          this.formGroup.getRawValue().contact_house_no || '',
        contact_village_no:
          this.formGroup.getRawValue().contact_village_no || '',
        contact_road: this.formGroup.getRawValue().contact_road || '',
        contact_province:
          this.formGroup.getRawValue().contact_province || '',
        contact_district:
          this.formGroup.getRawValue().contact_district || '',
        contact_sub_district:
          this.formGroup.getRawValue().contact_sub_district || '',
        contact_postal_code:
          this.formGroup.getRawValue().contact_postal_code || '',
        email: this.formGroup.getRawValue().email || '',
        secondary_email:
          this.formGroup.getRawValue().secondary_email || '',
        phone_number: this.formGroup.getRawValue().phone_number
          ? this.formGroup
              .getRawValue()
              .phone_number.replace(/\D/g, '')
          : '',
        secondary_phone_number: this.formGroup.getRawValue()
          .secondary_phone_number
          ? this.formGroup
              .getRawValue()
              .secondary_phone_number.replace(/\D/g, '')
          : '',
        property: this.formGroup.getRawValue().property || '',
        location: this.formGroup.getRawValue().location || '',
        building: this.formGroup.getRawValue().building || '',
        room_reserve: this.formGroup.getRawValue().room_reserve,
        duration_contract:
          this.formGroup.getRawValue().duration_contract,
        additional_details:
          this.formGroup.getRawValue().additional_details || '',
        number_of_occupants:
          this.formGroup.getRawValue().number_of_occupants || '',
      };
    }
  }

  submit(): void {
    const data = this.prepareData();
    if (!data) {
      this.modalService.dismissAll();
      return;
    }
    this.spinner.show();
    if (this.formGroup.value.type_user === 'individual') {
      this.subscription.push(
        this.customerService
          .editIndividual(this.individualId, data)
          .subscribe(
            () => {
              if (this.person?.id) {
                this.uploadSignature(this.person?.id);
              }
              this.alert.success('Update user success');
              this.back.emit(false);
              this.modalService.dismissAll();
              this.spinner.hide();
            },
            (err) => {
              this.alert.error(this.formatError(err.error));
              this.modalService.dismissAll();
              this.spinner.hide();
            },
          ),
      );
    } else {
      this.subscription.push(
        this.customerService
          .editCompany(this.companyId, data)
          .subscribe(
            () => {
              if (this.person?.id) {
                this.uploadSignature(this.person?.id);
              }
              this.alert.success('Update user success');
              this.back.emit(false);
              this.modalService.dismissAll();
              this.spinner.hide();
            },
            (err) => {
              this.alert.error(this.formatError(err.error));
              this.modalService.dismissAll();
              this.spinner.hide();
            },
          ),
      );
    }
  }

  formatError(error: any): string {
    if (error && Array.isArray(error)) {
      const errorText: string = error.join(',');
      return errorText;
    } else if (error && typeof error === 'object') {
      if ('non_field_errors' in error) {
        const nonFieldErrors = error.non_field_errors as string[];
        return nonFieldErrors.join('\n');
      }
      return error.committees || 'Unable to update information';
    }
  }

  imageCroppedPhoto(output: ImageCroppedEvent): void {
    this.croppedImagePhoto = output.base64;
    this.blobOutputPhoto = base64ToFile(output.base64, 'photo.png');
  }

  async onCropperReady(dimensions: Dimensions) {
    this.cropper =
      await this.imageProcessingService.getSignatureBoundary(
        this.imageCropperComponent.imageFile,
      );
  }

  loadImageFailedPhoto(): void {
    this.imageChangedEventPhoto = '';
    this.croppedImagePhoto = '';
    this.errorMsg = 'APPROVAL.ERROR-UNABLE-UPLOAD';
  }

  filePhotoChangeEvent(event: any): void {
    const file = event.target.files[0];
    this.fileInputPhoto = file;
    this.imageChangedEventPhoto = event;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {};
  }

  savePhoto(): void {
    const binaryFile = convertFile(this.blobOutputPhoto);
    const reader = new FileReader();
    reader.readAsDataURL(binaryFile);
    reader.onload = () => {
      this.photoPreview = reader.result;
      this.photo = reader.result;
    };
    this.close();
  }

  close(): void {
    this.modalService.dismissAll();
  }

  getLocationItem(): void {
    const params = {
      type: 'location',
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.locationItem = dropdown.location;
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
  }

  getItemProperty(event, templateChange = false): void {
    if (!event) {
      this.propertyItem = [];
      this.itemBuilding = [];
      this.formGroup.get('property').setValue(null);
      this.formGroup.get('building').setValue(null);
      return;
    }
    if (templateChange) {
      this.propertyItem = [];
      this.itemBuilding = [];
      this.formGroup.get('property').setValue(null);
      this.formGroup.get('building').setValue(null);
    }

    const params = {
      type: 'property',
      location: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.propertyItem = dropdown.property;
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
  }

  getBuildingItem(event, templateChange = false): void {
    if (!event) {
      this.itemBuilding = [];
      this.formGroup.get('building').setValue(null);
      return;
    }
    if (templateChange) {
      this.itemBuilding = [];
      this.formGroup.get('building').setValue(null);
    }
    const params = {
      type: 'building',
      property: event,
    };
    this.subscription.push(
      this.dropdownService.getDropdown(params).subscribe(
        (dropdown: DropdownResponse) => {
          this.itemBuilding = dropdown.building;
        },
        (error) => {
          this.alert.error(error.status);
        },
      ),
    );
  }

  onSelectCareer(event): void {
    if (event.value === 'other') {
      this.formGroup
        .get('job_other')
        .setValidators([Validators.required]);
    } else {
      this.formGroup.get('job_other').setValue(null);
      this.formGroup.get('job_other').setErrors(null);
      this.formGroup.get('job_other').clearValidators();
    }
    this.formGroup.updateValueAndValidity();
  }

  onChangeHouseNo(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_house_no.setValue(
        this.formGroup.get('house_no').value || null,
      );
      this.disableContactAddress();
    }
  }

  onChangeVillageNo(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_village_no.setValue(
        this.formGroup.get('village_no').value || null,
      );
      this.disableContactAddress();
    }
  }

  onChangeRoad(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_road.setValue(
        this.formGroup.get('road').value || null,
      );
      this.disableContactAddress();
    }
  }

  onChangeProvince(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_province.setValue(
        this.formGroup.get('province').value || null,
      );
      this.disableContactAddress();
    }
  }

  onChangeDistrict(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_district.setValue(
        this.formGroup.get('district').value || null,
      );
      this.disableContactAddress();
    }
  }

  onChangeSubDistrict(event): void {
    if (this.statusAddress) {
      this.formGroup.controls.contact_sub_district.setValue(
        this.formGroup.get('sub_district').value || null,
      );
      this.disableContactAddress();
    }
  }

  disableContactAddress(): void {
    this.formGroup.get('contact_house_no').disable();
    this.formGroup.get('contact_village_no').disable();
    this.formGroup.get('contact_road').disable();
    this.formGroup.get('contact_province').disable();
    this.formGroup.get('contact_district').disable();
    this.formGroup.get('contact_sub_district').disable();
    this.formGroup.get('contact_postal_code').disable();
  }

  formatDateFromLanguage(event) {
    return moment(event)
      .add(this.translate.currentLang === 'th' ? 543 : 0, 'year')
      .format('DD/MM/YYYY');
  }
}
